import styled from '@emotion/styled/macro'

export const TickerWrapper = styled.div`
  border: ${(props) =>
    props.border ? `1px solid ${props.theme.colors.secondary.greylight}` : '0'};
  border-radius: 2rem;
  background-color: ${(props) => props.theme.colors.primary.white};
  color: ${(props) => props.theme.colors.secondary.greymidnight};
  padding: 0.5rem 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

export const Ticker = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  p {
    display: inline-flex;
    white-space: nowrap;
  }
`
