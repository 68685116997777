import React, { useState, useEffect, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Text } from '../text'

import * as Styled from './OfferTicker.styled'

const renderNull = () => null

function useInterval(callback, delay = 0) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }

    return renderNull
  }, [delay])
}

const OfferTicker = ({ endTime, timeFormat, border }) => {
  const computeTimeLeft = () => {
    const now = moment()
    const end = moment(endTime, timeFormat)
    const dDays = Math.max(0, end.diff(now, 'days'))
    const dHours = Math.max(0, end.diff(now, 'hours'))
    const dMinutes = Math.max(0, end.diff(now, 'minutes'))
    const dSeconds = Math.max(0, end.diff(now, 'seconds'))

    const hourAmt = dHours - dDays * 24
    const minuteAmt = dMinutes - dHours * 60
    const secondAmt = dSeconds - dMinutes * 60
    const dayString = `${dDays} day${dDays > 1 ? 's' : ''}`
    const hourString = `${hourAmt} hr${hourAmt > 1 ? 's' : ''}`
    const minuteString = `${minuteAmt} min${minuteAmt > 1 ? 's' : ''}`
    const secondString = `${secondAmt} sec${secondAmt > 1 ? 's' : ''}`
    const timeUnitsZip = [
      [dDays, dayString],
      [hourAmt, hourString],
      [minuteAmt, minuteString],
      [secondAmt, secondString],
    ]

    if (end.diff(now) <= 0) return false
    return timeUnitsZip
      .map(unit => (unit[0] > 0 ? unit[1] : ''))
      .filter(str => !!str.length)
      .splice(0, 2)
      .join(', ')
  }

  const [timeLeft, setTimeLeft] = useState(computeTimeLeft())
  const [isRunning, setRunning] = useState(!!timeLeft)

  useInterval(
    () => {
      const newTimeLeft = computeTimeLeft()
      setTimeLeft(newTimeLeft)
      setRunning(!!newTimeLeft)
    },
    isRunning ? 1000 : null,
  )

  return (
    <Styled.TickerWrapper border={border}>
      {isRunning ? (
        <Styled.Ticker>
          <Fragment>
            <Text textStyle="ticker">Offer ends in&nbsp;</Text>
            <Text color="primary.black" textStyle="ticker">
              {timeLeft}
            </Text>
          </Fragment>
        </Styled.Ticker>
      ) : (
        <Text textStyle="p1">Offer expired.</Text>
      )}
    </Styled.TickerWrapper>
  )
}

OfferTicker.propTypes = {
  endTime: PropTypes.string.isRequired,
  timeFormat: PropTypes.string,
  border: PropTypes.bool,
}

OfferTicker.defaultProps = {
  timeFormat: 'YYYY-MM-DD HH-mm ZZ',
}

export default OfferTicker
