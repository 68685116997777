export const getFirstItemCurrencyCode = (items) => {
  const CURRENCY_CODE_KEY = 'currency_code'

  for (let item of items) {
    for (let prop in item) {
      if (item[prop].hasOwnProperty(CURRENCY_CODE_KEY)) {
        return item[prop][CURRENCY_CODE_KEY]
      }
    }
  }

  return 'USD'
}
