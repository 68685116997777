import clientData from '../../utils/client-data'

const country = clientData.locality.country || 'US'
const elmo = clientData.experimentation || {}
const isNativeIOS = clientData.isFromIOS

const brandCardsByCountry =
  {
    US: [
      {
        name: 'Apple',
        url_key: 'brands/apple',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-apple.jpg',
      },
      {
        name: 'Google Play',
        url_key: 'brands/google-play',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-google.jpg',
        condition: () => !isNativeIOS,
      },
      {
        name: 'Target',
        url_key: 'brands/target',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-target.jpg',
      },
      {
        name: 'Sony PlayStation Network',
        url_key: 'brands/playstationplus',
        img_url:
          'https://pics.paypal.com/00/c/gifts/us/playstation-top-new.jpg',
      },
      {
        name: 'Nintendo eShop',
        url_key: 'brands/nintendo-egift-card',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-nintendo.jpg',
      },
      {
        name: 'Uber',
        url_key: 'brands/uber',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-uber.jpg',
      },
      {
        name: "Domino's Pizza",
        url_key: 'brands/domino-s-pizza',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-dominos.jpg',
      },
      {
        name: 'Spotify',
        url_key: 'brands/spotify',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-spotify.jpg',
      },
      {
        name: "Lowe's",
        url_key: 'brands/lowes',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-lowes.jpg',
      },
      {
        name: 'Walmart',
        url_key: 'brands/walmart',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-walmart.jpg',
      },
      {
        name: 'Adidas',
        url_key: 'brands/adidas',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-adidas.jpg',
      },
      {
        name: 'Airbnb',
        url_key: 'brands/airbnb',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-airbnb.jpg',
      },
    ],
    DE: [
      {
        name: 'Apple iTunes',
        url_key: 'marken/apple',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-apple.jpg',
      },
      {
        name: 'Google Play',
        url_key: 'marken/google-play-gutscheincode',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-google.jpg',
        condition: () => !isNativeIOS,
      },
      {
        name: 'Nintendo eShop',
        url_key: 'marken/nintendo-eshop-cards',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-nintendo.jpg',
      },
      {
        name: 'Spotify',
        url_key: 'marken/spotify-digital',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-spotify.jpg',
      },
      {
        name: 'Xbox',
        url_key: 'marken/xbox-guthaben',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/xbox_logo.png',
      },
      {
        name: 'Netflix',
        url_key: 'marken/netflix',
        img_url: 'https://www.paypalobjects.com/ppdg/netflix_logo.png',
      },
      {
        name: 'Telekom',
        url_key: 'marken/telekom-guthabencode',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/telekom_logo.png',
      },
      {
        name: 'Vodafone',
        url_key: 'marken/vodafone-guthabencode',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/vodafone.png',
      },
    ],
    GB: [
      {
        name: 'Adidas',
        url_key: 'brands/adidas',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-adidas.jpg',
      },
      {
        name: 'Apple iTunes',
        url_key: 'brands/apple-uk',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-apple.jpg',
      },
      {
        name: 'Google Play',
        url_key: 'brands/google-play',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-google.jpg',
        condition: () => !isNativeIOS,
      },
      {
        name: 'Nintendo eShop',
        url_key: 'brands/nintendo-eshop-cards',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-nintendo.jpg',
      },
      {
        name: 'Spotify',
        url_key: 'brands/spotify-uk-99',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-spotify.jpg',
      },
      {
        name: 'Uber',
        url_key: 'brands/uber',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-uber.jpg',
      },
      {
        name: 'Xbox',
        url_key: 'brands/xbox-codes',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/xbox_logo.png',
      },
      {
        name: 'Vodafone',
        url_key: 'brands/vodafone',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/vodafone.png',
      },
    ],
    CA: [
      {
        name: 'Apple iTunes',
        url_key: 'gift-cards/apple',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-apple.jpg',
      },
      {
        name: 'Google Play',
        url_key: 'gift-cards/google-play',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-google.jpg',
        condition: () => !isNativeIOS,
      },
      {
        name: 'Sony PlayStation Network',
        url_key: 'gift-cards/playstation-store',
        img_url:
          'https://pics.paypal.com/00/c/gifts/us/playstation-top-new.jpg',
      },
      {
        name: 'Uber',
        url_key: 'gift-cards/uber',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-uber.jpg',
      },
      {
        name: 'Spotify',
        url_key: 'gift-cards/spotify',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-spotify.jpg',
      },
      {
        name: 'Sephora',
        url_key: 'gift-cards/sephora',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/sephora_logo.png',
      },
      {
        name: 'Groupon',
        url_key: 'gift-cards/groupon',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/groupon_logo.png',
      },
    ],
    AU: [
      {
        name: 'eBay',
        url_key: 'brands/ebay',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/ebay_logo.png',
      },
      {
        name: 'MYER',
        url_key: 'brands/myer',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/myer_logo.png',
      },
      {
        name: 'JB Hi-Fi',
        url_key: 'brands/jb-hi-fi',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/jb_logo.png',
      },
      {
        name: 'Rebel Sport',
        url_key: 'brands/rebel-sport',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/rebel_logo.png',
      },
      {
        name: 'Netflix',
        url_key: 'brands/netflix',
        img_url: 'https://www.paypalobjects.com/ppdg/netflix_logo.png',
      },
      {
        name: 'Spotify',
        url_key: 'brands/spotify',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-spotify.jpg',
      },
      {
        name: 'Uber',
        url_key: 'brands/ubergift',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-uber.jpg',
      },
    ],
    AT: [
      {
        name: 'Apple iTunes',
        url_key: 'marken/apple',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-apple.jpg',
      },
      {
        name: 'Google Play',
        url_key: 'digitale-gutscheine/google-play-gutscheincode',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-google.jpg',
        condition: () => !isNativeIOS,
      },
      {
        name: 'Nintendo eShop',
        url_key: 'digitale-gutscheine/nintendo-eshop-cards',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-nintendo.jpg',
      },
      {
        name: 'Spotify',
        url_key: 'digitale-gutscheine/spotify-digital',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/topbrand-spotify.jpg',
      },
      {
        name: 'Xbox',
        url_key: 'digitale-gutscheine/xbox-guthaben',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/xbox_logo.png',
      },
      {
        name: 'Netflix',
        url_key: 'digitale-gutscheine/netflix',
        img_url: 'https://www.paypalobjects.com/ppdg/netflix_logo.png',
      },
      {
        name: 'Telekom',
        url_key: 'digitale-gutscheine/telekom-wertkarte',
        img_url:
          'https://www.paypalobjects.com/digitalassets/c/gifts/us/telekom_logo.png',
      },
    ],
  }[country] || []

export default {
  name: 'brands',
  cards: brandCardsByCountry.filter(
    ({ condition }) => !condition || condition(elmo),
  ),
}
