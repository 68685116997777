import './init'
import App from './app'
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { analyticsInit } from './utils/analytics'
import { ContextProvider as WorldReadyContextProvider } from '@paypalcorp/worldready-react'
import clientData from './utils/client-data'

analyticsInit(clientData)
let root = document.getElementById('root')

if (!root) {
  root = document.createElement('div')
  document.body.appendChild(root)
}

render(
  <WorldReadyContextProvider locale={clientData.locality.locale}>
    <Router basename={clientData.requestURI}>
      <App />
    </Router>
  </WorldReadyContextProvider>,
  document.getElementById('root'),
)
