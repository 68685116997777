import React, { useState, useEffect, useContext } from 'react'
import lodash from 'lodash'
import URL from 'url-parse'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router' // eslint-disable-line import/no-extraneous-dependencies
import { withTheme } from '@emotion/react/macro'
import { Redirect } from 'react-router-dom'
import { graphql } from '@apollo/client/react/hoc'

import CartSummaryUI from './cart-summary.component'
import gqlGetStoreInfo from '../../graphql/getStoreInfo'
import gqlCreatePayment from '../../graphql/createPayment'
import { CartContext } from '../../context/cart-context'
import { trackImpression } from '../../utils/analytics'
import { log, handleSessionError } from '../../utils/error-tracking'
import trackCartEvent from '../../utils/track-cart-event'

const CartSummary = ({ history, storeInfo, createPayment }) => {
  const [loadingPaymentAdvice, setLoadingPaymentAdvice] = useState(false)
  const [soldOutPromotionsBySku, setSoldOutPromotionsBySku] = useState([])

  const { cartItems, loading, removeCartItem, updateCartItem } =
    useContext(CartContext)

  useEffect(() => {
    window.scrollTo(0, 0)

    trackImpression(
      'main:consumer:gifts:cart:',
      'main:consumer:gifts:cart:summary:::',
    )
  }, [])

  const logCheckoutError = (errMessage) => {
    log({
      file: 'cart-summary',
      err: { stack: errMessage },
    })

    trackCartEvent('cancelCart', [], {})
  }

  const getCheckoutOrigin = () => {
    const parts = window.location.hostname.split('.')

    // if it's localhost, we should use msmaster for checkout
    if (parts.includes('localhost')) {
      return 'https://www.msmaster.qa.paypal.com'
    }

    // if it is stage, use the same stage
    // if it is not stage, use the same origin as current site
    return window.location.origin
  }

  // eslint-disable-next-line consistent-return
  const handleBuyNow = async () => {
    setLoadingPaymentAdvice(true)

    trackCartEvent('preparepurchase', cartItems)

    let cartInfo = {}
    cartInfo.cartItems = cartItems

    try {
      const { data: createPaymentData } = await createPayment({
        variables: {
          cartInfo,
          stctype: new URL(window.location, true).query.stctype || '',
          sessionData: {
            utm_source:
              (sessionStorage && sessionStorage.getItem('utm_source')) || '',
          },
        },
      })

      const promotionSoldOutErrors = lodash.get(
        createPaymentData,
        'createPayment.promotionSoldOutErrors',
      )

      if (!lodash.isEmpty(promotionSoldOutErrors)) {
        logCheckoutError(
          'Promotions are sold out in cart, cancelling checkout and displaying error on cart',
        )
        setLoadingPaymentAdvice(false)
        setSoldOutPromotionsBySku(
          promotionSoldOutErrors.map((promoErr) => promoErr.sku),
        )
        return
      }

      const ecToken = lodash.get(createPaymentData, 'createPayment.ecToken')

      if (lodash.isEmpty(ecToken)) {
        logCheckoutError(
          'Could not get ecToken, cancelling cart and redirecting to error page',
        )

        history.push('/error')
        return
      }

      // redirect to Express Checkout
      const origin = getCheckoutOrigin()

      window.location.replace(
        `${origin}/cgi-bin/webscr?cmd=_express-checkout&useraction=commit&token=${createPaymentData.createPayment.ecToken}`,
      )
      return
    } catch (err) {
      logCheckoutError(
        'Exception in create payment promise, redirect to error page',
      )

      const errorState = {}

      try {
        errorState.txnLimitErrors = lodash.flattenDeep(
          err.graphQLErrors.map((_err) => JSON.parse(_err.message)),
        )
      } catch (_err) {
        // Don't handle bad error message
      }

      history.push('/txnerror', errorState)
    }
  }

  const handleRemoveItem = async (itemToRemove) => {
    removeCartItem(itemToRemove)
  }

  const handleUpdateItem = async (itemToUpdate, updatedItem) => {
    updateCartItem(itemToUpdate, updatedItem)
  }

  const handleEmptyCartBtnClick = () => {
    history.push('/all')
  }

  if (loading || storeInfo.loading || loadingPaymentAdvice) {
    return (
      <div className="vx_has-spinner-large" style={{ paddingTop: '400px' }} />
    )
  }

  if (storeInfo.error) {
    handleSessionError(storeInfo.error)
    return <Redirect to="/error" />
  }

  return (
    <CartSummaryUI
      storeInfo={storeInfo}
      cartItems={cartItems}
      soldOutPromotionsBySku={soldOutPromotionsBySku}
      handleBuyNow={handleBuyNow}
      handleRemoveItem={handleRemoveItem}
      handleUpdateItem={handleUpdateItem}
      handleEmptyCartBtnClick={handleEmptyCartBtnClick}
    />
  )
}

CartSummary.propTypes = {
  history: PropTypes.object.isRequired,
  storeInfo: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.object,
    getStoreInfo: PropTypes.shape({
      store_id: PropTypes.string,
      terms_and_conditions: PropTypes.string,
      locale: PropTypes.string,
    }),
  }),
  createPayment: PropTypes.func.isRequired,
}

export default lodash.flowRight(
  withTheme,
  graphql(gqlGetStoreInfo, { name: 'storeInfo' }),
  graphql(gqlCreatePayment, { name: 'createPayment' }),
)(withRouter(CartSummary))
